<template>
  <div class="pl_add" v-loading="loading">
    <el-tree
      :data="tableDatas"
      :props="defaultProps"
      :render-content="renderContent"
    ></el-tree>
  </div>
</template>
<script>
export default {
  name: "PLUpLoadLive",
  data: function () {
    return {
      tableDatas: [],
      count: 0,
      loading: false,
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  async created() {
    this.loading = true;
    this.$datas.meta = {kind:1};
    let res = await this.$datas.getClass;
    this.count = res.data.count;
    let datas = res.data.data;
    let deal_datas = [];
    for (let i in datas) {
      let deal_data = { label: datas[i].className, children: [] };
      //处理阶段数据
      for (let j in datas[i].tms) {
        let item = datas[i].tms[j];
        let orExist = false;
        for (let k in deal_data.children) {
          if (deal_data.children[k].label == item.jieduan) {
            orExist = true;
            deal_data.children[k].datas.unshift(item);
            break;
          }
        }
        if (orExist == false) {
          deal_data.children.push({
            label: item.jieduan,
            datas: [item],
          });
        }
      }
      deal_datas.push(deal_data);
    }
    for(let i = 0; i < deal_datas.length; i++){
        if(deal_datas[i].children.length <= 0){
            deal_datas.splice(i, 1);
            i--;
        }
    }
    deal_datas.sort((item1, item2)=>{
        let className1 = item1.label.replace(/[A-Z]+/, "");
        let className2 = item2.label.replace(/[A-Z]+/, "");
        return className2-className1;
    });
    this.tableDatas = deal_datas;
    console.log('++++==+++=====');
    console.log(deal_datas);
    this.loading = false;
  },
  methods: {
    handleNodeClick(data) {
      console.log(data);
      this.$router.push({ name: "JDChengJI", query: { data } });
    },
    renderContent(h, { node }) {
      if (!node.data.children) {
        return (
          <div class="custom-tree-node">
            <span>{node.label}</span>
            <span>
              <el-button
                size="mini"
                type="text"
                on-click={() => this.handleNodeClick(node.data)}
              >
                <i class="el-icon-view"></i>
              </el-button>
            </span>
          </div>
        );
      } else {
        return (
          <div class="custom-tree-node">
            <span>{node.label}</span>
          </div>
        );
      }
    },
  },
};
</script>
<style lang="less">
.custom-tree-node {
  font-size: 14px;
  padding: 10px 0;
  color: #555;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-button--mini,
.el-button--small {
  font-size: 15px;
  margin-left: 20px;
}
</style>
